.suggestionContainer {
    display: flex;
    align-items: center;
}
.suggestionText {
    color: black;
    height: 40px;
    font-size: 18pt;
    margin-left: 10px;
}
.suggestionImage {
    width: 90px;
    margin: 5px;
    border-radius: 5px;
}
