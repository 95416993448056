input {
    width: 100%;
    height: 50px;
    font-size: 18pt;
    border-radius: 10px;
    padding: 0 10px;
}

.suggestions {
    position: relative;
    background-color: white;
    width: 100%;
    border-radius: 10px;
}

.hidden {
    display: none;
}