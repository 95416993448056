.container {
  margin-top: 40px;
  width: 100%;
}

.header {
  color: white;
  margin: 0;
  font-size: 2.2rem;
  letter-spacing: 2px;
}

.list {
  margin-top: 10px;
}

.movieItem {
  margin-top: 20px;
  display: flex;
}

.movieTitle {
  display: flex;
  align-items: center;
  font-size: 2rem;
  letter-spacing: 2px;
  margin-left: 10px;
}

.moviePoster {
  height: 200px;
}

.separator {
  margin-top: 30px;
}

.line {
  position: relative;
  margin: 5px 0;
  width: 90%;
  max-width: 510px;
  height: 1px;
  border-bottom: 1px solid rgba(225, 225, 225, 0.7);
}