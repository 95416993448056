.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  color: rgba(225, 225, 225, 0.9);
  /* background-color: red; */
}

.formWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3% 13%;
  margin-top: 80px;
}

.separator {
    height: 40px;
}
